/* eslint-disable no-template-curly-in-string */
export default {
  auth: {
    email: 'Email',
    name: 'Name',
    password: 'Password',
    sign_in_submit: 'Sign in',
    sign_in_message: 'Sign In to your account',
    verification_code: 'Verification code',
    verification_code_tip: 'Get a 6-digit code from your Google Authenticator app and enter the code',
    activate_authenticator: 'Activate Authenticator',
  },
  user: {
    first_name: 'First name',
    last_name: 'Last name',
    user_not_found: 'User not found',
    cannot_remove_yourself: 'You cannot remove yourself',
  },
  app: {
    back: 'Back',
    submit_form_final: 'Finish',
    submit_form_next: 'Next',
    send: 'Send',
  },
  errors: {
    fill_field: 'Please fill out this field',
    choose_one: 'Please choose one',
    no_server_response: 'Cannot connect to the server. Please check your internet connection and try again',
    email_required: 'Email is required',
    password_required: 'Password is required',
    required: 'Required',
    email_invalid: 'Email invalid',
    server_connection_error: 'Failed connection to the server',
  },
  page_not_found: {
    title: 'Page not found',
    back: 'Back',
  },
  buttons: {
    save: 'Save',
    submit: 'Submit',
    cancel: 'Cancel',
    back: 'Back',
    unassign: 'Unassign',
    delete: 'Delete',
  },
  init2FA: {
    configure_code_generator: 'Сonfigure the Google Authenticator',
    steps:
      '<ol><li>Download the Google Authenticator app from </li>the <a>href={playMarketLink} Play Market</a> or the <a>href={appStoreLink} App Store</a> <li>In the application, click Set up an account</li><li>Select Scan Barcode</li><li>To make sure it works, get a 6-digit code from your Authenticator app</li><li>Enter the code and tap Verify\n If your code is correct, you will see a confirmation\n If your code is incorrect, try again.</li></ol>',
  },
  project_pdf_requests: {
    title: 'Project PDF requests',
    filter: 'Filter',
    email: 'Email',
    name: 'Name',
    projectName: 'Project name',
    clientIP: 'Client IP',
    created_at: 'Created',
    pdf: 'PDF',
    utmParams: 'UTM params',
    phone_number: 'Phone number',
    project_implementation_start_point: {
      label: 'Project implementation start point',
      options: {
        within_three_months: 'Within 3 months',
        in_three_to_six_months: 'In 3 to 6 months',
        later_than_in_six_months: 'Later than in 6 months',
      },
    },
  },
  messages: {
    feedback: 'Feedback',
    userMessage: "User's message",
    responded: 'Responded',
    unresponded: 'Unresponded',
    filter: 'Filter',
    message_sent: 'Message has been sent',
    empty: 'No messages',
    name: 'Name',
    message: 'Message',
    date: 'Date',
    placeholder_for_editor: 'Type in your message or copy and paste from notepad',
  },
  projects: {
    utmParams: 'UTM params',
    filter: 'Filter',
    name: 'Name',
    price: 'Price',
    pdf: 'Pdf',
    excel: 'Excel',
    created: 'Created',
    last_updated: 'Last updated',
    xlsx_export: 'Projects Xlsx',
    clientIP: 'Client IP',
    archived: 'Archived',
    types: {
      new_building: 'New single-family house',
      new_apartment: 'Buying apartment',
      buying_house: 'Buying family-house',
      renovation_house: 'Renovation house',
      property_valuation: 'Property valuation',
      lifestyle_calculator: 'Lifestyle calculator',
    },
  },
  loan_consultants: {
    title: 'Loan consultants',
    loan_consultant_added: 'The loan consultant has been added',
    delete_loan_consultant: 'Delete loan consultant',
    delete_loan_consultant_message: 'Are you sure, you want to delete loan consultant - {name}',
    loan_consultant_deleted: 'The loan consultant has been deleted',
    delete: 'Delete',
    download_btn: 'Download report',
    add_loan_consultant_btn: 'Add Loan Consultant',
    name: 'Name',
    email: 'Email',
  },
  admins: {
    title: 'Admins',
    add_admin_btn: 'Add Admin',
    edit_admin_btn: 'Edit',
    add_admin_success: 'Admin has been added',
    edit_admin_info_success: 'Admin info has been edited',
    active: 'Active',
    lastLogin: 'Last login',
    name: 'Name',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    password: 'Password',
  },
  notifications: {
    empty: 'No notifications',
    message: 'Message',
    date: 'Date',
  },
  financing_requests: {
    project_name: 'Project name',
    email: 'Email',
    sender: 'Sender',
    created_at: 'Created at',
    user: 'User',
    message: 'Message',
    empty: 'No requests',
    download: 'Download as pdf',
  },
  select_values: {
    not_important: 'Not important',
    important: 'Important',
    very_important: 'Very important',
  },
  customer_data_analytics: {
    generate_report: 'Generate report',
    customer_name: 'Customer name',
    customer_email: 'Customer email',
    ip_address: 'IP-Address',
    alone_or_partner: 'Partnership',
    age: 'Age',
    created_at: 'Created at',
    kids_quantity: 'Kids',
    monthly_net_salary: 'Income',
    monthly_net_salary_partner: 'Income-partner',
    other_revenues: 'Other income',
    amount_with_loan_costs: 'Loan sum',
    last_project_type: 'Last project type',
    last_project_created_at: 'Last project created at',
    new_car: 'New car',
    savings: 'Savings',
    luxuries: 'Luxuries',
    hobbies: 'Hobbies',
    pension_and_insurances: 'Pension and insurances',
    socializing: 'Socializing',
    taking_a_break: 'Traveling',
    technology: 'Technology',
    pdf: 'PDF',
  },
  co2_report: {
    category_name: 'CO₂ relevant category',
    user_input: 'User value',
    number_of_status_planned: `Number of Status 'Planed'`,
    co2_savings: 'CO₂ savings t/a',
    new_windows: 'Window exchange',
    insulation_top_ceiling: 'Insulation top ceiling',
    insulation_basement_ceiling: 'Insulation basement ceiling',
    insulation_facade: 'Facade insulation',
    controlled_living_space_ventilation: 'Active living space ventilation',
    heating_system: 'Heating exchange',
    solar_power_system: 'Solar power system',
    go_to_details: 'Go to details',
    back_to_co2_report: 'Back to CO₂ report',
    username: 'User name',
    energy_ghg_balance_before: 'Energy ghg balance before planning',
    energy_ghg_balance_after: 'Energy ghg balance after planning',
  },
  hypo_noe_api_request: {
    project_name: 'Project name',
    project_type: 'Project type',
    details: 'Details',
    purchase_price: 'Purchase price',
    parameter: 'Parameter',
    value: 'Value',
    customers: 'Customers',
    status: 'Status',
    is_ok: 'Is ok?',
    error: 'Error',
    user_info: 'User info',
    guest: 'Guest',
    created_at: 'Created at',
    retry_request: 'Retry request',
  },
  iFrameSettings: {
    add_allowed_origin_btn: 'Add origin',
    add_allowed_origin_success: 'Allowed origin has been added',
    delete_allowed_origin: {
      title: 'Delete allowed origin',
      message: 'Are you sure, you want to delete the "{value}" allowed origin',
      success: 'Allowed origin has been deleted',
    },
    allowed_origin: 'Allowed origin',
    unique: '${path} must be unique',
    url: '${path} must be a valid URL',
    duplicated: 'The allowed origin is already added',
  },
  apiLogs: {
    table: {
      headers: {
        eventType: 'Event type',
        method: 'Method',
        url: 'Url',
        statusCode: 'Status code',
        data: 'Data',
        details: 'Details',
        createdAt: 'CreatedAt',
      },
    },
  },
  page_titles: {
    login: 'Login',
    projects: 'Projects',
    analytics: 'Analytics',
    messages: 'Messages',
    admins: 'Admins',
    logout: 'Logout',
    login2FA: 'Authenticator',
    notifications: 'Notifications',
    financing_requests: 'Financing requests',
    project_pdf_requests: 'PDF requests',
    loan_consultants: 'Loan consultants',
    customer_data_analytics: 'Customer data analytics',
    co2: 'CO2',
    co2_report: 'Reporting',
    co2_projects: 'Projects',
    hypo_noe_api_requests_report: 'HYPO-NOE API requests',
    iFrameSettings: 'iFrame settings',
    priceHubbleApiLogs: 'Price Hubble API logs',
    effiApiLogs: 'Effi API logs',
  },
};

export const backendErrors = {
  access_denied: 'Access denied',
  something_went_wrong: 'Something went wrong',
  form_data_is_missing: 'Form data is missing',
  auth: {
    login_failed: 'Login failed. Please try again',
    email_exists: 'User with this email is already registered',
    verify_account: 'You have to verify your email before login.',
    password_is_required: 'Password is required',
    not_authorized: 'Not authorized',
    temporary_password_is_required: 'Your first login must have a temporary password',
    wrong_password: 'Wrong password',
    account_has_no_password:
      'Your account does not have a password because you signed up with a social services (Google or Facebook)',
  },
  invitation: {
    email_exists: 'Such an email already exists in the system',
    cannot_resend_temp_password: `You can't send a temporary password because the user has already changed it`,
    cannot_delete_invited_user: `You can't delete a user account because he has changed the temporary password`,
  },
  admin: {
    disable_2_fa: 'Disable 2FA before initiating it again.',
    otp_is_invalid: 'One-time password is not valid. Please try again',
    last_admin: 'You last admin',
    otp_is_required: 'One time password  is required, please provide it',
  },
  hypo_noe_api_request: {
    request_not_found: 'Request not found',
    retry_is_not_available: '@Unfortunately, you could not resubmit this request because it was successful',
  },
  user: {
    cannot_deactivate_himself: 'You cannot deactivate yourself',
    user_not_found: 'User not found',
  },
  project: {
    not_found: 'Project not found',
  },
};
