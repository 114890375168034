import React, { useMemo } from 'react';
import 'components/common/AlertOverlay.scss';
import AlertGraphQLError from 'components/common/AlertGraphQLError';
import { CCard, CCardBody, CCardHeader, CCol } from '@coreui/react';
import { useIntl } from 'react-intl';
import { formatDate } from 'utils/helpers';
import usePagination from 'hooks/usePagination';
import useApiLogs from 'hooks/useApiLogs';
import ApiLogsTable from 'components/apiLogsTable/ApiLogsTable';

const EffiApiLogsPage = () => {
  const { formatMessage: t } = useIntl();
  const { currentPage, changePage } = usePagination();
  const { apiLogs, total, loading, error } = useApiLogs({ currentPage, apiName: 'effi' });

  const columns = useMemo(
    () => [
      { key: 'createdAt', label: t({ id: 'apiLogs.table.headers.createdAt' }) },
      { key: 'eventType', label: t({ id: 'apiLogs.table.headers.eventType' }) },
      { key: 'statusCode', label: t({ id: 'apiLogs.table.headers.statusCode' }) },
      { key: 'method', label: t({ id: 'apiLogs.table.headers.method' }) },
      { key: 'data', label: t({ id: 'apiLogs.table.headers.data' }) },
      { key: 'extraDetails', label: t({ id: 'apiLogs.table.headers.details' }) },
    ],
    [t],
  );

  const items = useMemo(
    () =>
      apiLogs.map(({ eventType, method, statusCode, data, details, createdAt }) => ({
        createdAt: formatDate(createdAt),
        data: data ? JSON.stringify(JSON.parse(data), undefined, 2) : '',
        eventType,
        method,
        statusCode: statusCode || '',
        extraDetails: details || '',
      })),
    [apiLogs],
  );

  return (
    <CCol>
      <CCard>
        <CCardHeader>
          <h4>{t({ id: 'page_titles.effiApiLogs' })}</h4>
        </CCardHeader>

        <CCardBody>
          {error ? (
            <AlertGraphQLError error={error} />
          ) : (
            <ApiLogsTable {...{ items, changePage, columns, currentPage, total, loading }} />
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
};

export default EffiApiLogsPage;
